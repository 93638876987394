import { WorkStatus } from "@/store/modules/activity.store";
import { formatCurrency } from "@/utils/formatters";
import { Mandate } from "@/store/modules/mandate.store";

function generateEmptyPropertyForActivity(activity: Activity, type: "HOUSE" | "APPARTMENT" | "LAND" | "OFFICE" | "COMMERCIAL" | "INDUSTRIAL" | "PARKING" | null = null): Property {
  return {
    id: NaN,
    activity_id: activity.id,
    address: {
      country: "",
      city: "",
      zip: "",
      street: "",
      number: "",
      additional: "",
    },
    description: "",
    characteristics: generateEmptyPropertyCharacteristic(type),
    whise_url: "",
    pictures: [],
    created_at: "",
    updated_at: "",
    sort: "",
  };
}

function generateEmptyPropertyCharacteristic(type: "HOUSE" | "APPARTMENT" | "LAND" | "OFFICE" | "COMMERCIAL" | "INDUSTRIAL" | "PARKING" | null = null): PropertyCharacteristics {
  return {
    sale: {
      offer_from: null,
      minimum_offer: null,
      min_rent_price: null,
      offer_active: null,
      construction_cost: null,
      ground_value: null,
      price_estimation: null,
      price_range_min: null,
      price_range_max: null,
      vat_system: null,
      vat_percent: null,
      vat_ground_value: null,
      registration_rights_percent: null,
    },
    area: {
      length: null,
      width: null,
      facade_width: null,
      parking_length: null,
      parking_width: null,
      parking_height: null,
      building_length: null,
      building_width: null,
      lateral_space: null,
      reversing_zone: null,
      in_allotment: null,
      flood_risk_o_peil: null,
      flood_risk_2023: null,
      g_score: null,
      p_score: null,
      signal_area: null,
      worg: null,
      stores: null,
      stores_distance: null,
      schools: null,
      schools_distance: null,
      public_transport: null,
      public_transport_distance: null,
      sportscenter: null,
      sportscenter_distance: null,
      highways: null,
      highways_distance: null,
      station: null,
      station_distance: null,
    },
    type: type,
    subtype: null,
    purpose: null,
    project_vat: null,
    floor: null,
    floors: null,
    livable_area: null,
    ground_area: null,
    show_address: null,
    zone: [],
    cadastral_income: null,
    cadastral_section: null,
    cadastral_surface: null,
    cadastral_number: null,
    cadastral_department: null,
    features: {
      bedrooms: 0,
      bathrooms: 0,
      has_garden: null,
      garden_surface: null,
      garden_orientation: null,
      has_garage: null,
      garage_count: 0,
      charging_station: null,
      has_parking: null,
      has_outside_parking: null,
      has_inside_parking: null,
      inside_parking_count: null,
      outside_parking_count: null,
      has_terrace: null,
      terrace_surface: null,
      terrace_orientation: null,
      furnished: null,
      furnished_immovable_property: null,
      furnished_immovable_property_price: null,
      has_water: null,
      has_gas: null,
      has_plumbing: null,
      has_electricity: null,
    },
    building: {
      type: null,
      state: null,
      is_renovated: null,
      renovation_year: null,
      construction_year: null,
      plans_available: null,
      syndicus_monthly_rate: null,
      syndicus_information: null,
    },
    connections: {
      network_provider: null,
      network_connection_available: null,
      cable_television: null,
    },
    installation: {
      heating: {
        has_centralized_heating: null,
        has_decentralized_heating: null,
        has_water_heating: null,
        centralized_heating: [],
        centralized_heating_fuel: [],
        decentralized_heating: [],
        water_heating: [],
        heating_type: null,
      },
      electrical: {
        conform_norm: null,
        has_meter: null,
        meter: null,
      },
      ventilation: {
        has_ventilation_system: null,
        system_grade: null,
      },
      roller_shutters: {
        automatic: null,
        has_roller_shutters: null,
        material: [],
        material_other: null,
        full_installation: null,
      },
      garage_door: {
        automatic: null,
        has_garage_door: null,
        material: null,
        material_other: null,
      },
    },
    wellness: {
      pool: null,
      sauna: null,
      steam_bath: null,
      jacuzzi: null,
    },
    other: {
      elevator: null,
      home_automation: null,
      compliant_elevator: null,
    },
    equipment: {
      kitchen: {
        dishwasher: null,
        air_extractor: null,
        oven: null,
        combi_oven: null,
        microwave_oven: null,
        steam_oven: null,
        sink_type: null,
        coffee_machine: null,
        stove: null,
        stove_type: null,
        fridge: null,
      },
      bathroom: {
        baths: null,
        showers: null,
        sinks: null,
        toilets: null,
        guest_toilets: null,
        double_sinks: null,
      },
      security: {
        alarm_system: null,
        intercom: null,
        videophone: null,
        smoke_detectors: null,
        camera_surveillance: null,
        gated_access: null,
        fire_detector: null,
      },
      specialized: {
        solar_water_heater: null,
        water_softener: null,
        has_solar_panels: null,
        solar_panels: 0,
        solar_panel_certificate: null,
        solar_panel_certificates_per_year: null,
        solar_panel_amount_per_certificate: null,
        rainwater_collection: {
          water_wells: 0,
          water_well_volume_liters: [],
          toilet: null,
          outdoor_faucet: null,
          indoor_faucet: null,
          washing_machine: null,
        },
        septic_tank_available: null,
        septic_tank_volume: null,
      },
    },
    construction: {
      exterior_finishing: {
        material: null,
        layers: null,
      },
      wall_finishing: {
        material: [],
        material_other: null,
      },
      ceiling_finishing: {
        material: [],
        material_other: null,
      },
      floor_finishing: {
        material: [],
        material_other: null,
      },
      facade: {
        material: [],
        insulation_available: null,
        insulation_type: null,
        insulation_thickness: null,
        material_other: null,
      },
      roof: {
        material: [],
        insulation_available: null,
        insulation_type: null,
        insulation_thickness: null,
        material_other: null,
      },
      mezzanine: {
        material: [],
        material_other: null,
      },
      exterior_joinery: {
        windows: [],
        material: [],
        notes: null,
      },
      asbestos_materials: null,
    },
    sales_promise: {
      electricity_inspection_conform: null,
      fuel_oil_tank_compliant: null,
      asbestos_present: null,
      struck_by_red_line: null,
      epc_label: null,
      epc_score: null,
      reference: null,
      mandatory_renovation: null,
      e_peil: null,
      habitable_after_deed_period: null,
      has_resident_allowance: null,
      resident_allowance: null,
      resident_allowance_period: null,
      notes: null,
      availability: null,
      servitude: null,
      building_permit: null,
      subdivision_permit: null,
      pre_emption_right: null,
      terrain_destination: null,
      summons: null,
      summons_type: null,
      protected_heritage: null,
      heritage: null,

      electricity_certificate_available: null,
      soil_certificate_available: null,
      asbestos_certificate_available: null,
      oiltank_certificate_available: null,
      electricity_certificate_date: null,
      asbestos_certificate_date: null,
    },
    rooms: [],
    notes: null,
  };
}

function activityIsDossier(activity?: Activity): boolean {
  if (!activity) {
    return false;
  }
  return [
    WorkStatus.newDossier,
    WorkStatus.startUp,
    WorkStatus.archived,
    WorkStatus.firstOffer,
    WorkStatus.published,
    WorkStatus.option,
    WorkStatus.sold,
    WorkStatus.firstOffer,
    WorkStatus.done,
    WorkStatus.onHold,
  ].includes(activity.work_status);
}

interface CommissionCalculation {
  commission: number;
  calculation: Array<{ note: string; commission?: number }>;
}

function getActivityCommission(activity?: Activity): CommissionCalculation {
  let commissionCalculation: CommissionCalculation = {
    commission: 0,
    calculation: [],
  };

  if (!activity) {
    commissionCalculation.calculation.push({ note: "Geen dossier gevonden", commission: 0 });

    return commissionCalculation;
  }

  let commercialGuesture = getCommercialGuestures(activity);

  if (activity.fixed_commission) {
    commissionCalculation.commission = activity.fixed_commission;
    commissionCalculation.calculation.push({ note: "Vaste commissie" });
    commissionCalculation.calculation.push({ note: `${formatCurrency(activity.fixed_commission)}`, commission: activity.fixed_commission });
    commissionCalculation.calculation.push({ note: `` });

    // Inclusief BTW
    if (activity.commission_vat_inclusive) {
      let commisionNote = {
        note: `${formatCurrency(commissionCalculation.commission)} / 1.21: ${formatCurrency(commissionCalculation.commission / 1.21)}`,
        commission: commissionCalculation.commission,
      };
      commissionCalculation.commission = commisionNote.commission = commissionCalculation.commission / 1.21;
      commissionCalculation.calculation.push({ note: `Inclusief ingegeven ` });
      commissionCalculation.calculation.push(commisionNote);

      if (commercialGuesture) {
        commissionCalculation.calculation.push({ note: `` });
        commissionCalculation.calculation.push({ note: `Commerciële geste` });
        commissionCalculation.calculation.push({
          note: `${formatCurrency(commissionCalculation.commission)} - ${formatCurrency(commercialGuesture)}: ${formatCurrency(commissionCalculation.commission - commercialGuesture)}`,
          commission: commercialGuesture,
        });
        commissionCalculation.commission = commissionCalculation.commission - commercialGuesture;
      }

      return commissionCalculation;
    }

    // Exclusief BTW
    let commisionNote = {
      note: `${formatCurrency(commissionCalculation.commission)} * 1: ${formatCurrency(commissionCalculation.commission)}`,
      commission: commissionCalculation.commission,
    };
    commissionCalculation.commission = commisionNote.commission = commissionCalculation.commission;
    commissionCalculation.calculation.push({ note: `Exclusief ingegeven` });
    commissionCalculation.calculation.push(commisionNote);

    if (commercialGuesture) {
      commissionCalculation.calculation.push({ note: `` });
      commissionCalculation.calculation.push({ note: `Commerciële geste` });
      commissionCalculation.calculation.push({
        note: `${formatCurrency(commissionCalculation.commission)} - ${formatCurrency(commercialGuesture)}: ${formatCurrency(commissionCalculation.commission - commercialGuesture)}`,
        commission: commercialGuesture,
      });
      commissionCalculation.commission = commissionCalculation.commission - commercialGuesture;
    }

    return commissionCalculation;
  }

  if (!activity.relative_commission || !activity.property_sale || (!activity.property_sale.offer_from && !activity.property_sale.sold_for)) {
    commissionCalculation.calculation.push({ note: "Geen % commissie of geen verkoop gevonden", commission: commissionCalculation.commission });

    return commissionCalculation;
  }

  if (activity.property_sale.hasOwnProperty("sold_for") && activity.property_sale.sold_for) {
    commissionCalculation.commission = (activity.property_sale.sold_for / 100) * activity.relative_commission;
    commissionCalculation.calculation.push({ note: `${activity.relative_commission}% op verkoopprijs (settings)` });
    commissionCalculation.calculation.push({
      note: `${formatCurrency(activity.property_sale.sold_for)} * ${activity.relative_commission / 100}: ${formatCurrency(commissionCalculation.commission)}`,
      commission: commissionCalculation.commission,
    });
    commissionCalculation.calculation.push({ note: `` });
  } else if (activity.property_sale.hasOwnProperty("approved_bid_from") && activity.property_sale.approved_bid_from) {
    commissionCalculation.commission = (activity.property_sale.approved_bid_from / 100) * activity.relative_commission;
    commissionCalculation.calculation.push({ note: `${activity.relative_commission}% op aanvaard bod` });
    commissionCalculation.calculation.push({
      note: `${formatCurrency(activity.property_sale.approved_bid_from)} * ${activity.relative_commission / 100}: ${formatCurrency(commissionCalculation.commission)}`,
      commission: commissionCalculation.commission,
    });
    commissionCalculation.calculation.push({ note: `` });
  } else {
    commissionCalculation.commission = (activity.property_sale.offer_from / 100) * activity.relative_commission;
    commissionCalculation.calculation.push({ note: `${activity.relative_commission}% op verkoopprijs` });
    commissionCalculation.calculation.push({
      note: `${formatCurrency(activity.property_sale.offer_from)} * ${activity.relative_commission / 100}: ${formatCurrency(commissionCalculation.commission)}`,
      commission: commissionCalculation.commission,
    });
    commissionCalculation.calculation.push({ note: `` });
  }

  // Inclusief BTW
  if (activity.commission_vat_inclusive) {
    let commisionNote = {
      note: `${formatCurrency(commissionCalculation.commission)} / 1.21: ${formatCurrency(commissionCalculation.commission / 1.21)}`,
      commission: commissionCalculation.commission,
    };
    commissionCalculation.commission = commisionNote.commission = commissionCalculation.commission / 1.21;
    commissionCalculation.calculation.push({ note: `Inclusief ingegeven` });
    commissionCalculation.calculation.push(commisionNote);

    if (commercialGuesture) {
      commissionCalculation.calculation.push({ note: `` });
      commissionCalculation.calculation.push({ note: `Commerciële geste` });
      commissionCalculation.calculation.push({
        note: `${formatCurrency(commissionCalculation.commission)} - ${formatCurrency(commercialGuesture)}: ${formatCurrency(commissionCalculation.commission - commercialGuesture)}`,
        commission: commercialGuesture,
      });
      commissionCalculation.commission = commissionCalculation.commission - commercialGuesture;
    }

    return commissionCalculation;
  }

  // Exclusief BTW
  let commisionNote = {
    note: `${formatCurrency(commissionCalculation.commission)} * 1: ${formatCurrency(commissionCalculation.commission)}`,
    commission: commissionCalculation.commission,
  };
  commissionCalculation.commission = commisionNote.commission = commissionCalculation.commission;
  commissionCalculation.calculation.push({ note: `Exclusief ingegeven` });
  commissionCalculation.calculation.push(commisionNote);

  if (commercialGuesture) {
    commissionCalculation.calculation.push({ note: `` });
    commissionCalculation.calculation.push({ note: `Commerciële geste` });
    commissionCalculation.calculation.push({
      note: `${formatCurrency(commissionCalculation.commission)} - ${formatCurrency(commercialGuesture)}: ${formatCurrency(commissionCalculation.commission - commercialGuesture)}`,
      commission: commercialGuesture,
    });
    commissionCalculation.commission = commissionCalculation.commission - commercialGuesture;
  }

  return commissionCalculation;
}

function getCommercialGuestures(activity: Activity): number {
  if (!activity.commercial_gesture) {
    return 0;
  }

  if (activity.commercial_gesture_vat_inclusive) {
    return activity.commercial_gesture / 1.21;
  }

  return activity.commercial_gesture;
}

function getMandateCandidates(mandate: Mandate) {
  let candidates: any[] = [];

  if (!mandate || !mandate.details) {
    return [];
  }

  if (mandate.details.company_candidates && mandate.details.company_candidates.length > 0) {
    candidates = [...candidates, ...mandate.details.company_candidates];
  }

  if (mandate.details.ground_owner_company_candidates && mandate.details.ground_owner_company_candidates.length > 0) {
    candidates = [...candidates, ...mandate.details.ground_owner_company_candidates];
  }

  if (mandate.details.construction_owner_company_candidates && mandate.details.construction_owner_company_candidates.length > 0) {
    candidates = [...candidates, ...mandate.details.construction_owner_company_candidates];
  }

  if (mandate.details.candidates && mandate.details.candidates.length > 0) {
    candidates = [...candidates, ...mandate.details.candidates];
  }

  return candidates;
}

export { generateEmptyPropertyForActivity, generateEmptyPropertyCharacteristic, activityIsDossier, getActivityCommission, getMandateCandidates };
